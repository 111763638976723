
import { Component, Vue } from 'vue-property-decorator'
import Examine from '@/views/assetScheduling/transfer/Examine.vue'
@Component({
  name: 'scheduling',
  components: { Examine }
})
export default class ScheduleAdd extends Vue {
  private info = {
    orderDate: '',
    projectId: '',
    remarks: '',
    assetList: [],
    transferList: []
  }

  private showDialog = false
  private id = ''
  private page = 1
  private size = 10
  private total = 0
  private assetStateList = ['进行中', '已结束', '已打回']
  private orderList = ['发起', '调入方审批', '调出方审批']
  private resultList = ['通过', '驳回']
  private rules = {
    projectId: [
      { required: true, message: '请选择调入项目', trigger: ['blur', 'change'] }
    ]
  }

  get orderId () {
    return this.$route.params.orderId || ''
  }

  get useId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  created () {
    this.id = this.orderId
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetAllocateByOrderId, {
      orderId: this.orderId
    }).then(res => {
      this.info = res || []
      this.getList()
    })
  }

  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetImageByPage, {
      page: this.page,
      size: this.size,
      orderId: this.$route.params.orderId
    }).then(res => {
      this.info.assetList = res.list || []
      this.total = res.total || 0
    })
  }

  isShowExamine (row: any) {
    return (row.transferState === '1' && this.useId === row.putUserId) || (row.transferState === '2' && this.useId === row.outUserId)
  }

  success () {
    this.getData()
  }
}
